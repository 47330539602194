

label, input, p, ul, span {
    
    font-size: 13px;
    font-variant: normal;
    padding: 0;
    margin: 0;
  }
  
  html {
    height: 100%;
  }
  
  body {
    font-family: "Helvetica Neue", Helvetica, Arial;
    display: flex;
    justify-content: center;
    min-height: 100%;
    margin: 0;
  }
  body::before{
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
    display: block;
    background-image: url('./img/background-image.png');
    background-size:cover;
    width: 100%;
    height: 100%;
    filter: blur(3px);
  }
  h1, h2, h3 , h4, h5, h6{
      margin: 5px 0;
  }
  h4{
      font-size: 14px;
  }
  h6{
      padding-top: 10px;
  }
  ul{
      list-style: none;
  }
  ul li{
      margin-bottom: 5px;
  }
  ul li span:first-child{
      position: relative;
      width: 125px;
      display: inline-block;
  }
  .list-initial::after {
      content: ":";
      position: absolute;
      right: 10px;
  }
  .download-app img{
     width: 150px; 
  }
  .download-app a:first-child{
    float: left;
  }
  
  .download-app a:nth-child(2){
    float: right;
  }
  
  .download-app::after{
      clear: both;
      content: "";
  }
  
  form {
    width: 480px;
    margin: 0 auto;
  }
  
  .group {
    background: white;
    box-shadow: 0px 1px 2px 1px rgb(105 105 105 / 45%);
    border-radius: 13px;
    margin-top: 15px;
  }
  
  .group label input.field::placeholder{
    color: #6b6b6b;
    font-size: 13px;
  }
  
  .group label input.field{
    font-size: 15px;
  }
  
  label {
    position: relative;
    color: #8898AA;
    font-weight: 300;
    height: 40px;
    line-height: 40px;
    display: flex;
    flex-direction: row;
  }
  
  #root{
    padding: 0 20px;
    box-sizing: border-box;
    width: 100%;
  }
  
  .group label:not(:last-child) {
    border-bottom: 1px solid #F0F5FA;
  }
  
  .dark{
      background-color: rgba(0, 0, 0, .05);
  }
  .logo{
    text-align: center;
    margin-bottom: 5px;
    padding-bottom: 5px;
    box-shadow: 0 6px 4px -4px #c7c7c7;
  }
  .upper span{
      padding-top: 15px;
  }
  
  .upper span, .upper input, .upper select{
     display: block;
     width: 100%;
     outline: none;
  }
  
  .check-wrapper{
    position: absolute;
    width: 25px;
    height: 25px;
    right: 12px;
    cursor: pointer;
    display: block;
  }
  
  /* hide default */
  .check-wrapper input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* customizing */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #888;
    border-radius: 50%;
  }
  
  /* .check-wrapper:hover input ~ .checkmark {
    background-color: #26a69a;
  } */
  
  .check-wrapper input:checked ~ .checkmark {
    background-color: #26a69a;
  }
  
  .check-wrapper .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  .check-wrapper input ~ .checkmark:after {
    display: block;
  }
  
  .search-field{
    width: 300px;
    position: relative;
  }
  
  .search-field button{
    position: absolute;
    right: 20px;
    top: 30px;
    background-color: rgb(220, 110, 125);
    border: none;
    color: #fff;
    padding: 5px 8px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .individual .upper input.pay-fields{
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgb(220, 110, 125);
    color: #000;
    font-size: 16px;
    margin-top: 10px;
    padding-left: 10px;
    box-sizing: border-box;
    border-radius: 0;
    max-width: 480px;
    width: 100%;
  }

  .individual .upper input.mobile-input {
    padding-left: 80px;
    box-sizing: border-box;
  }
  
  .upper span i{
      color: red;
      font-size: 14px;
  }
  
  .amount-block h3{
    font-size: 15px;
  }
  
  .amount-block h3 span{
    position: relative;
    display: inline-block;
  }
  .amount-block h3 span:first-child{
    width: 135px;
  }
  
  .amount-block h3 span:first-child:after{
    content: ":";
    position: absolute;
    right: 10px;
  }
  
  .card-info{
      box-shadow: 0px 1px 2px 1px rgb(105 105 105 / 45%);
      border-radius: 10px;
      padding: 0 10px;
      background-color: #fff;
    }
    
    #card-errors{
      padding: 8px 0 0 11px;
    }
    
  
  .card-info select, .card-info input{
    line-height: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
  }
  .card-info select{
    padding-left: 6px;
    font-size: 15px;
  }
  
  div.group label > span {
    width: 120px;
    text-align: right;
    margin-right: 30px;
  }
  
  .field {
    background: transparent;
    font-weight: 300;
    border: 0;
    color: #31325F;
    outline: none;
    flex: 1;
    padding-right: 10px;
    padding-left: 20px;
    cursor: text;
    width: 50%;
  }
  
  .card-extra-err > div{
    float: left;
    width: calc(50% - 20px);
    padding-left: 20px;
    box-sizing: content-box;
  }
  
  .card-extra-err:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .field::-webkit-input-placeholder {
    color: #CFD7E0;
  }
  
  .field::-moz-placeholder {
    color: #CFD7E0;
  }
  
  #zip{
      outline: none;
      font-size: 16px;
  }
  
  #zip::placeholder, span.cc-number{
      font-weight: 300;
      font-family: sans-serif;
      color: #000;
      font-size: 13px;
      letter-spacing: 0.4px;
  }
  
  span.cc-number{
      position: relative;
      top: 2px;
  }
  
  button#pay-submit {
    float: left;
    display: block;
    background-color: rgb(220, 110, 125);
    border: solid 1px rgba(255, 138, 154, 1);
    color: white;
    box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.10), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border: 0;
    margin-top: 20px;
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    height: 40px;
    line-height: 38px;
    outline: none;
    cursor: pointer;
    margin-bottom: 30px;
  }
  
  .outcome {
    float: left;
    width: 100%;
    padding-top: 8px;
    min-height: 24px;
    text-align: center;
  }
  
  .success,
  .error {
    display: none;
    font-size: 13px;
  }
  
  .success.visible,
  .error.visible {
    display: inline;
  }
  
  .error {
    color: #E4584C;
  }
  
  .success {
    color: #666EE8;
  }
  
  .success .token {
    font-weight: 500;
    font-size: 13px;
  }
  
  .text-center{
    text-align: center;
  }
  
  .location span{
    position: relative;
    top: -8px;
    font-size: 15px;
  }
  
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: #fff;
    background-image: none;
  }
  /* Remove IE arrow */
  select::-ms-expand {
    display: none;
  }
  /* Custom Select */
  .select {
    position: relative;
    display: flex;
    width: 250px;
    height: 35px;
    line-height: 3;
    background: #fff;
    overflow: hidden;
    margin: 0 auto;
    box-shadow: 1px 1px 5px 0px rgb(213, 213, 213);
    border: 1px solid rgba(176, 175, 175, 0.75);
  }
  select {
    flex: 1;
    padding: 0 .5em;
    color: #464646;
    cursor: pointer;
    outline: none;
    font-size: 16px;
  }
  /* Arrow */
  .select::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 1em;
    font-size: 13px;
    background: #fff;
    cursor: pointer;
    pointer-events: none;
    -webkit-transition: .25s all ease;
    -o-transition: .25s all ease;
    transition: .25s all ease;
    color: #b3b3b3;
  }
  
  .enf-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .enf-column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    border: 1px solid #eee;
    font-size: 11px;
    align-items: center;
  }
  
  .ticket-item{
    border: 1px solid rgb(213, 213, 213);
    padding: 10px 20px;
    margin-top: 10px;
    position: relative;
  }
  
  .pay-duration{
      margin-top: 10px;
  }
  
  .pay-duration span{
      float: left;
      display: block;
      width: 140px;
  }
  
  .pay-duration select{
      text-align: center;
      text-align-last: center;
      border: none;
      border-bottom: 1px solid rgb(220, 110, 125);
      background-color: transparent;
      border-radius: 0;
      font-size: 16px;
  }
  
  .country-with-phone{
      position: relative;
  }
  
  .country-with-phone .country-code {
      width: 85px;
      padding: 5px 0;
      position: absolute;
      top: -9px;
  }
  
  .country-with-phone .input-wrapper {
      width: 100%;
  }
  
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  .loader {	
    font-size: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.6);
    border-right: 1.1em solid rgba(255, 255, 255, 0.6);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.6);
    border-left: 1.1em solid rgb(220, 110, 125);
    -webkit-animation: load8 0.5s infinite linear;
    animation: load8 0.5s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  .pay-card{
      position: relative;
      color: #8898AA;
      font-weight: 300;
      height: 40px;
      line-height: 40px;
      display: flex;
      flex-direction: row;
  }
  
  .pay-card > div{
      flex: 1 1;
  }
  
  .error-msg{
      font-size: 12px;
      color: #cc0000;
      line-height: 12px;
      font-weight: 400;
      padding: 3px 0;
  }
  
  .em-custom{
      padding-top: 5px;
      padding-left: 15px;
  }
  
  .upper input.mobile-input{
      padding-left: 80px;
      box-sizing: border-box;
  }
  
  .success-message {
      text-align: center;
      width: 300px;
      position: absolute;
      top: 11%;
      left: 50%;
      transform: translateX(-50%);
      height: 180px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .12), 0 0 10px 0 rgba(0, 0, 0, .08);
      background-color: #fff;
      z-index: 100;
      padding-top: 25px;
    }
  
    #sm-close{
        position: absolute;
        color: #b5b5b5;
        right: 10px;
        top: 1px;
        font-size: 34px;
        line-height: 34px;
        cursor: pointer;	
    }
    
    .success-message__icon {
      max-width: 75px;
    }
    
    .success-message__title {
      color: #3dc480;
      transform: translateY(25px);
      opacity: 1;
      transition: all 200ms ease;
    }
    .active .success-message__title {
      transform: translateY(0);
      opacity: 1;
    }
    
    .success-message__content {
      color: #b8babb;
      transform: translateY(25px);
      /* opacity: 0; */
      transition: all 200ms ease;
      transition-delay: 50ms;
    }
    .active .success-message__content {
      transform: translateY(0);
      opacity: 1;
    }
    
    .icon-checkmark circle {
      fill: #3dc480;
      transform-origin: 50% 50%;
      /* transform: scale(0); */
      transition: transform 200ms cubic-bezier(0.22, 0.96, 0.38, 0.98);
    }
    .icon-checkmark path {
      transition: stroke-dashoffset 350ms ease;
      transition-delay: 100ms;
    }
    .active .icon-checkmark circle {
      transform: scale(1);
    }
    label#register{
        height: auto;
        line-height: 14px;
        margin-top: 20px;
        color: #464646;
        cursor: pointer;
        display: inline-block;
    }
    label#register input{
      margin-right: 5px;
    }
    .lot-details{
        background-color: #fff;
        max-height: calc(100vh - 132px);
        overflow-y: scroll;
        width: 280px;
        position: fixed;
        top: 32px;
          left: 50%;
          transform: translateX(-50%);
        z-index: 101;
        padding: 10px;
    }
    .lot-details h4{
        padding-top: 7.5px;
    }
    .lot-details tr th{
      font-size: 13px;
      text-align: center;
      width: 100px;
    }
    .lot-details tr td{
        font-size: 12px;
        text-align: center;
    }
    .lot-details tbody tr{
        padding-top: 5px;
    }
   
  @media (max-width: 500px) {
    #root, form {
      width: 100%;
    }
    .loader {	
        left: 35%;
    }
  }
  